<template>
  <div class="view pa24">
    <el-button
      class="mr10"
      type="primary"
      v-if="jurisdictionA  || userInfo.roleId || userInfo.main"
      @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false
      "
      >添加分公司</el-button
    >
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index" label="序号" />
        <el-table-column prop="branchCompanyName" align="center" label="分公司名称" show-overflow-tooltip/>
        <el-table-column prop="branchPhone" align="center" label="分公司电话" show-overflow-tooltip/>
        <el-table-column prop="branchAddress" align="center" label="分公司地址" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              "
              >编辑</el-button
            >
            <!-- <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @onConfirm="del(scope.row.videoId)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？"
            >
              <el-button
                slot="reference"
                class="ml10"
                style="color: #fd634e"
                type="text"
                >删除</el-button
              >
            </el-popconfirm> -->
             <customPopconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row.id)"
              icon="el-icon-info"
              icon-color="red"
              title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
                  >删除</el-button
                >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="添加分公司"
      :visible.sync="centerDialogVisible"
      @open="openDialog"
      @close="close"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="95px"
      >
        <el-form-item label="分公司名称" prop="branchCompanyName">
          <el-input v-model="ruleForm.branchCompanyName"></el-input>
        </el-form-item>
        <el-form-item label="分公司电话" prop="branchPhone">
          <el-input v-model="ruleForm.branchPhone"></el-input>
        </el-form-item>
        <el-form-item label="分公司地址" prop="branchAddress">
          <el-input v-model="ruleForm.branchAddress"></el-input>
        </el-form-item>        
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {
  queryPage,
  add,
  deleteById,
  updateData,
  update,
  getGoodsInfoPC,
} from "@/api/videoManage";
import { getDataTimeSec } from "@/utils";
import {selectWebsiteInfo,insertCompanyVideo,delCompanyVideo,moveCompanyVideo} from '@/api/companyManage.js'
import {fetchCompany,updCompany,updBranchCompany} from '@/api/companyManage.js'
let ac;
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
      centerDialogVisible: false,
      dialogDetailVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      isUpdate:true,
      fileList: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      ruleForm: {
        id:"",
        branchCompanyName: "",
        branchPhone: "",
        branchAddress: "",
      },
      rules: {
        branchCompanyName: [{ required: true, message: "请输入分公司名称", trigger: "blur" }],
        branchPhone: [{ required: true, message: "请输入分公司电话", trigger: "blur" }],
        branchAddress: [{ required: true, message: "请输入分公司地址", trigger: "blur" }],
      },
      jurisdictionA:'',
      jurisdictionB:'',
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  filters: {
    getDataTimeSec(timeStamp) {
      var dateTime = new Date(timeStamp);
      var no1new = dateTime.valueOf();
      var year = dateTime.getFullYear();
      var month = dateTime.getMonth() + 1;
      var day = dateTime.getDate();
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var second = dateTime.getSeconds();
      var now = new Date();
      var now_new = now.valueOf();
      var milliseconds = 0;
      var timeSpanStr;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }

      milliseconds = now_new - no1new;

      if (milliseconds <= 1000 * 60 * 1) {
        timeSpanStr = "刚刚";
      } else if (
        1000 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟前";
      } else if (
        1000 * 60 * 60 * 1 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时前";
      } else if (
        1000 * 60 * 60 * 24 < milliseconds &&
        milliseconds <= 1000 * 60 * 60 * 24 * 15
      ) {
        timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天前";
      } else if (
        milliseconds > 1000 * 60 * 60 * 24 * 15 &&
        year == now.getFullYear()
      ) {
        timeSpanStr = year + "-" + month + "-" + day;
      } else {
        timeSpanStr = year + "-" + month + "-" + day;
      }
      return timeSpanStr;
    },
  },
  created() {

    this.getGoodsInfoPC();
  },
  mounted(){
    this.fetchCompany();
  },
  // beforeDestroy() {
  //   ac.removeEventListener("onConfirm", this.setValue);
  // },
  methods: {
    async fetchCompany() {

      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        queryType: 'web' ,
      };
      if(this.jurisdictionA){
        data.queryType = 'web'
        if(this.jurisdictionB){
          data.queryType = ''
        }
      }else if(this.jurisdictioB){
        data.queryType = ''
      }

      try {
        this.loading = true;
        const result = await fetchCompany({companyId:this.$store.state.loginRoot.userInfo.companyId});

        this.loading = false;
        const { branchCompanyName } = result.data;
        this.tableData = branchCompanyName?JSON.parse(branchCompanyName):[];
        console.log(this.tableData)
        // this.total = total;
      } catch (error) {
        this.loading = false;
      }
    },
    /**@method 加载商品列表 */
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.goodsListPageSize,
        pageNum: this.goodsListCurrentPage,
      };

      try {
        this.goodsListLoading = true;
        const result = await getGoodsInfoPC(data);
        this.goodsListLoading = false;
        const { total, list } = JSON.parse(result?.data?.pageInfo);
        this.goodsList = list;
        this.goodsListTotal = total;
      } catch (error) {
        this.goodsListLoading = false;
      }
      // const reslut
    },
    /**@method 模态框打开时的回调 */
    openDialog() {
      let map = new BMap.Map("baidumap");
      ac = new BMap.Autocomplete({
        //建立一个自动完成的对象
        input: "suggestId",
        location: map,
      });
      ac.addEventListener("onConfirm", this.setValue);
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        branchCompanyName: "",
        branchPhone: "",
        branchAddress: "",
      }),
        (this.fileList = []);
      this.bEdit = false;
    },
    setValue(e) {
      const {
        item: { value },
      } = e;
      this.ruleForm.address = `${value.city}${value.district}${value.business}`;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    goodsListSizeChange(val) {
      this.goodsListPageSize = val;
      this.getGoodsInfoPC();
    },
    goodsListCurrentChange(val) {
      this.goodsListCurrentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goodsDelete(index){
      this.ruleForm.videoGoods.splice(index,1)
    },
    queding() {
      this.ruleForm.videoGoods = [];
      this.multipleSelection.forEach((item) => {
        let data = {
          companyId: item.companyId,
          createdTime: item.createTime,
          videoId: this.ruleForm.videoId,
          describes: item.describes,
        };
        this.ruleForm.videoGoods.push(data);
        this.dialogDetailVisible = false;
      });
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm = data;

      this.bdetermine = b;
      this.bEdit = b;
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            this.$message({
              message: "正在保存",
            });       
            if (this.editUpdate) {
               for(var i=0;i<this.tableData.length;i++){
                if(this.tableData[i].id===this.ruleForm.id){
                   this.tableData[i].branchCompanyName=this.ruleForm.branchCompanyName
                   this.tableData[i].branchPhone=this.ruleForm.branchPhone
                   this.tableData[i].branchAddress=this.ruleForm.branchAddress
                }
              }
              let data = {
                ...this.ruleForm,
              }; 
              data.branchCompanyName=JSON.stringify(this.tableData)
              await updBranchCompany(data);
            } else {
              var arr=[]
              this.ruleForm.id=1
              if(this.tableData.length>0){
                for(var i=0;i<this.tableData.length;i++){
                arr.push(this.tableData[i].id)
                }
               this.ruleForm.id=Math.max.apply(null,arr)+1
              }
               this.tableData.push(this.ruleForm)
               let data = {
                ...this.ruleForm,
              }; 
               data.branchCompanyName=JSON.stringify(this.tableData)
               await updBranchCompany(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.dialogDetailVisible = false
            this.fetchCompany()
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
          var arr=[]
          for(var i=0;i<this.tableData.length;i++){
                if(this.tableData[i].id!=val){
                  arr.push(this.tableData[i])
                }
              }
              let data = {
                ...arr,
              }; 
        data.branchCompanyName=JSON.stringify(arr)
        await updBranchCompany(data);
        const reslut= await this.fetchCompany();
        this.$message({
              message: '成功',
              type: "success",
            });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-cover .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-cover .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>
